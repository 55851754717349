<template>
  <div>
    <NavBar />
    <task-form />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import TaskForm from "../components/TaskForm";

export default {
  name: "Task",

  components: {
    NavBar,
    TaskForm,
  },
};
</script>
