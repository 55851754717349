<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
      <v-alert v-show="errorAxios" class="mb-6" type="error">
        {{ $t("meeting_table.error_connection") }}
      </v-alert>

      <v-dialog v-model="loading" fullscreen>
        <v-container
          fluid
          fill-height
          style="background-color: rgba(255, 255, 255, 0.4)"
        >
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              :size="60"
              :width="7"
              color="primary"
            >
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>

      <div
        v-show="isEditing == 0 && loadExistTasks == 0"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        {{ $t("task_form.info_create") }}
      </div>
      <div
        v-show="isEditing == 0 && loadExistTasks == 1"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        {{ $t("task_form.info_edit") }}
        {{ $t("task_form.number") }} {{ idm }}
      </div>
      <div
        v-show="isEditing == 1"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        {{ $t("task_form.info_show") }}
        {{ $t("task_form.number") }} {{ idm }}
      </div>
      <v-divider></v-divider>
      <div class="pt-6 pb-4">
        <p class="headline text--secondary">
          {{ $t("task_form.info") }}
        </p>
      </div>
      <v-row>
        <v-col cols="12" md="6" v-if="showName">
          <v-textarea
            prepend-icon="mdi-shape-outline"
            auto-grow
            rows="1"
            row-height="15"
            v-model="textTaskName"
            :rules="requiredField"
            :label="$t('task_form.task_name')"
            required
            disabled
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="textProject"
            prepend-icon="mdi-assistant"
            :label="$t('task_form.project')"
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-textarea
            auto-grow
            rows="1"
            row-height="15"
            v-model="textMeetingWithId"
            prepend-icon="mdi-home"
            :label="$t('task_form.meeting_with_ovm')"
            disabled
          ></v-textarea>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="textOvm"
            prepend-icon="mdi-home"
            :label="$t('task_form.ovm')"
            disabled
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="textTaskType"
            prepend-icon="mdi-android-messages"
            :label="$t('task_form.taskType')"
            disabled
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" md="6" v-if="showDate">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="isEditing == 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('task_form.date_title')"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                :rules="requiredField"
                required
                @click:clear="date = null"
                :disabled="isEditing == 1"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="date"
              locale="sk-SK"
              scrollable
              @change="menuDate = false"
              :disabled="isEditing == 1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false">
                {{ $t("task_form.cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="$refs.menuDate.save(date)">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" lg="6" v-if="showResponsible">
          <v-row>
            <v-col cols="10">
              <v-combobox
                clearable
                class="mt-6"
                v-model="selectResponsiblePerson"
                @input.native="
                  actualResponsiblePerson = $event.srcElement.value
                "
                prepend-icon="mdi-account"
                :items="itemPersons"
                :label="$t('task_form.who_responsible')"
                :rules="[
                  requiredFieldComboboxResponsible(actualResponsiblePerson),
                ]"
                @change="actualResponsiblePerson = selectResponsiblePerson"
                required
                :disabled="isEditing == 1"
              ></v-combobox>
            </v-col>

            <v-col cols="1" class="pt-10 text-right">
              <v-btn
                v-if="
                  !!actualResponsiblePerson &&
                  !this.isExistPerson(actualResponsiblePerson)
                "
                :disabled="isEditing == 1"
                fab
                color="primary"
                @click="onChangePerson()"
              >
                <v-icon dark> mdi-folder-plus-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" md="6" v-if="showStatus">
          <v-combobox
            class="mt-6"
            v-model="selectStatus"
            prepend-icon="mdi-lead-pencil"
            :items="itemStatus"
            :label="$t('task_form.status')"
            :rules="[requiredFieldComboboxStatus]"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogNewPerson" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("task_form.create_and_insert")
              }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-account"
                      v-model="textName"
                      :label="$t('task_form.name')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-at"
                      v-model="textEmail"
                      :label="$t('meeting_minutes_form.email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-cellphone"
                      v-model="textPhone"
                      :label="$t('meeting_minutes_form.phone')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-cog-outline"
                      v-model="textPosition"
                      :label="$t('task_form.position')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="selectOvmUser"
                      prepend-icon="mdi-home"
                      :items="itemOvm"
                      :label="$t('task_form.organization')"
                    ></v-combobox>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddPerson()">
                {{ $t("task_form.close") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="addPerson()">
                {{ $t("task_form.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogError" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("task_form.error_validation")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogError = false">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogSave" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t("task_form.save_successful") }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSave = false">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogSaveWord" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("task_form.save_word_successful")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSaveWord = false">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <div class="pt-8">
        <v-divider></v-divider>
      </div>

      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("task_form.header_coment") }}
        </p>
      </div>

      <v-textarea
        outlined
        label="Zadajte komentár...."
        v-model="textAreaComment"
        :disabled="isEditing == 1"
      ></v-textarea>

      <v-row class="pb-16 pt-6">
        <v-spacer></v-spacer>
        <v-col cols="12" lg="3" class="text-center">
          <v-btn
            :disabled="isEditing != 1"
            dense
            color="primary"
            @click="concreteMeeting()"
          >
            {{ $t("task_form.continue_meeting") }}
          </v-btn>
        </v-col>
        <v-col
          v-show="isEditing == 0 && editable()"
          class="text-center"
          cols="12"
          lg="3"
        >
          <v-btn color="primary" @click="saveOnline()">
            {{ $t("task_form.submit") }}
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="12" lg="3">
          <v-btn color="primary" @click="saveWord()">
            {{ $t("task_form.word") }}
          </v-btn>
        </v-col>

        <v-col
          class="text-center"
          cols="12"
          lg="3"
          v-show="isEditing == 1 && editable()"
        >
          <v-btn class="ml-10" color="primary" @click="goEditing()">
            <v-icon dark class="mr-2"> mdi-tooltip-edit </v-icon>
            {{ $t("task_form.editing") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>


<script>
import axios from "axios";
import docxtemplater from "docxtemplater";
import jszip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import { VueOfflineStorage } from "vue-offline";

export default {
  data: () => ({
    idm: "",
    idThisMeeting: "",
    canEdit: false,
    admin: false,
    showName: false,
    showDate: false,
    showStatus: false,
    showResponsible: false,
    actualResponsiblePerson: "",
    id_token: "",
    textTaskType: "",
    textProject: "",
    loading: true,
    actualLoading: 0,
    errorAxios: false,
    responseFromLoading: "",
    itemStatusObj: [],
    itemOvmObj: [],
    persons: [],
    isEditing: false,
    loadExistTasks: false,
    textAreaComment: "",
    textTaskName: "",
    menuDate: null,
    textEmail: "",
    date: null,
    meetingId: "",
    textMeetingName: "",
    textMeetingWithId: "",
    textOvm: "",
    selectResponsiblePerson: "",
    selectStatus: "",
    dialogNewPerson: "",
    textName: "",
    textPosition: "",
    textPhone: "",
    selectOvmUser: "",
    dialogError: "",
    dialogSave: false,
    dialogSaveWord: false,
    itemPersons: [],
    itemStatus: [],
    itemOvm: [],
    itemTaskTypeObj: [],
    itemTaskType: [],
    valid: false,
    requiredField: [(v) => !!v || "Toto pole je potrebné vyplniť"],
    saveJson: {
      id: 1,
      date: "2020-10-23",
      meeting_id: 2,
      meeting_name: "Nezmysel",
      meeting_date: "2020-12-13",
      ovm_id: 3,
      ovm_name: "Ministerstvo nervov",
      responsible_by_id: 234,
      responsible_by_position: "sadf",
      responsible_by_ovm: "asdfasdf",
      responsible_by_ovm_id: "asdfasdf",
      state_id: 1,
      state_name: "v1",
      comment: "hhj jh jh hj n kjjkn",
    },
  }),
  async mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken"]),
  },
  methods: {
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },

    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.go(-2).catch(() => {});
      this.completeLoadingBlock();
    },

    actualTaskType() {
      if (this.textTaskType == "U – úloha") {
        this.showName = true;
        this.showDate = true;
        this.showStatus = true;
        this.showResponsible = true;
      }
      if (this.textTaskType == "R – riziko") {
        this.showName = true;
        this.showDate = false;
        this.showStatus = false;
        this.showResponsible = false;
      }
      if (this.textTaskType == "O – otvorená") {
        this.showName = true;
        this.showDate = true;
        this.showStatus = true;
        this.showResponsible = true;
      }

      if (this.textTaskType == "D – dohoda") {
        this.showName = true;
        this.showDate = false;
        this.showStatus = false;
        this.showResponsible = false;
      }
      if (this.textTaskType == "I – informácia") {
        this.showName = true;
        this.showDate = false;
        this.showStatus = false;
        this.showResponsible = false;
      }
      if (this.textTaskType == "P – predpoklad") {
        this.showName = true;
        this.showDate = false;
        this.showStatus = false;
        this.showResponsible = false;
      }

      if (this.textTaskType == "V – výstup") {
        this.showName = true;
        this.showDate = false;
        this.showStatus = false;
        this.showResponsible = false;
      }
    },

    requiredFieldComboboxResponsible() {
      let value = this.actualResponsiblePerson;
      if (value) {
        if (!this.isExistPerson(value)) {
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },
    requiredFieldComboboxStatus(value) {
      if (value) {
        if (!this.isExistStatus(value)) {
          return "Tento status nie je v systéme, prosim vyberte zo zoznamu dostupných statusov";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    isExistStatus(name) {
      if (this.itemStatus.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    async loadData() {
      this.id_token = this.getToken;
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();

      await this.loadEditingApprovalTask();

      await Promise.all([
        this.loadPersons(),
        this.loadOvm(),
        this.loadTaskType(),
        this.loadStatus(),
      ]).then((values) => {
        this.controlEditing();
      });

      // await this.loadPersons();
      // await this.loadOvm();
      // await this.loadTaskType();
      // await this.loadStatus();
      // await this.controlEditing();
    },
    goEditing() {
      this.isEditing = false;
      this.loadExistTasks = true;
    },

    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },

    loadEditingApprovalTask() {
      return axios
        .get(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_EDITING_APPROVAL_TASK +
            "/" +
            this.$route.params.id,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.admin = response.data.admin;
          this.canEdit = response.data.canEdit;
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    editable() {
      if (this.admin == true) return true;
      return this.canEdit && this.canEdit == true;
    },

    controlEditing() {
      if (this.$route.params.id) {
        this.loadExistTasks = true;
        this.editingID = this.$route.params.id;
        if (this.$router.currentRoute.name === "TaskShow") {
          this.isEditing = true;
        }
        this.setLoadingBlock();
        axios
          .get(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_GET_TASK +
              "/" +
              this.$route.params.id,
            { headers: { Authorization: `Bearer ${this.id_token}` } }
          )
          .then((response) => {
            let res = response.data;
            this.idm = res.project.acronym + res.meetingId + "." + res.uid;
            this.idThisMeeting = res.project.acronym + res.meetingId;
            this.responseFromLoading = response.data;
            this.meetingId = res.meetingId;
            this.textOvm = res.ovmName;

            this.textTaskType = res.taskType.name;
            this.textProject = res.project.name;
            if (res.deadline) {
              this.date = res.deadline.substring(0, 10);
            }
            this.textAreaComment = res.comment;
            if (res.person) {
              this.selectResponsiblePerson =
                res.person.name +
                ", " +
                res.person.position +
                ", " +
                res.person.ovm.name;
              this.actualResponsiblePerson =
                res.person.name +
                ", " +
                res.person.position +
                ", " +
                res.person.ovm.name;
            }
            if (res.status) {
              this.selectStatus = res.status.name;
            }
            let helpMeetingDate = "";
            if (res.meetingDate)
              helpMeetingDate = " (" + res.meetingDate.substring(0, 10) + ")";
            this.textMeetingName = res.meetingTopic;
            this.textMeetingWithId =
              this.idThisMeeting + ": " + res.meetingTopic + helpMeetingDate;
            if (res.name) {
              this.textTaskName = res.name;
            }
            this.errorAxios = false;
            this.completeLoadingBlock();
            this.actualTaskType();
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      }
    },
    loadTaskType() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASKTYPE, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemTaskTypeObj = response.data;

          this.itemTaskType = [
            ...new Set(this.itemTaskTypeObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("taskTypes", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
          this.itemTaskType.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadOvm() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_OVM, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemOvmObj = response.data;

          this.itemOvm = [...new Set(this.itemOvmObj.map((item) => item.name))];
          this.saveOfflineMode("ovm", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
          return "";
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.persons = response.data;
          this.itemPersons = this.persons.map(function (p) {
            return p.name + ", " + p.position + ", " + p.ovm.name;
          });
          this.saveOfflineMode("persons", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    loadStatus() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_STATUS, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemStatusObj = response.data;

          this.itemStatus = [
            ...new Set(this.itemStatusObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("status", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    isExistPerson(name) {
      if (this.itemPersons.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    loadFile(url, callback) {
      JSZipUtils.getBinaryContent(url, callback);
    },
    saveWord() {
      if (this.$refs.form.validate()) {
        this.$refs.form.validate();
        let concreteDate = "";
        if (this.date) {
          concreteDate = this.date.substring(0, 10);
        }
        if (!this.textAreaComment) {
          this.textAreaComment = "";
        }
        let offlineJson = {
          task_type: this.textTaskType,
          name: this.textTaskName,
          meeting: this.textMeetingName,
          ovm: this.textOvm,
          responsible_person: this.selectResponsiblePerson,
          deadline: concreteDate,
          state: this.selectStatus,
          comment: this.textAreaComment,
        };
        let offlineName = this.textTopic;
        let offlineState = this.selectVersion;
        this.loadFile(
          "/ReportTemplates/task_template.docx",
          function (error, content) {
            if (error) {
              throw error;
            }
            let zip = new jszip(content);
            let doc = new docxtemplater(zip, { linebreaks: true });

            doc.setData(offlineJson);
            try {
              doc.render();
            } catch (error) {
              let e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties,
              };
              // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
              throw error;
            }
            // docx generating
            let out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            let namefile =
              "Zápis_DI_" + offlineName + offlineState + "_ver.docx";

            // `MyDocument.docx`
            saveAs(out, namefile); // You can pass this blob to a custom file saver component in the project.
          }
        );
        this.dialogSaveWord = true;
      } else {
        this.dialogError = true;
      }
    },
    concreteMeeting() {
      this.$router.push({
        name: "MeetingShow",
        params: { id: this.meetingId },
      });
    },
    saveOnline() {
      if (this.$refs.form.validate()) {
        let personItem = this.persons.filter(
          (p) =>
            p.name + ", " + p.position + ", " + p.ovm.name ===
            this.selectResponsiblePerson
        );

        let statusItem = this.itemStatusObj.filter(
          (p) => p.name === this.selectStatus
        );

        let concretePerson = null;
        if (personItem[0]) {
          concretePerson = personItem[0];
        }

        let concreteStatus = null;
        if (statusItem[0]) {
          concreteStatus = statusItem[0];
        }
        let concreteDate = null;

        if (this.date) {
          concreteDate = this.date.substring(0, 10) + "T00:00:00.000+00:00";
        }
        let body = {
          person: concretePerson,
          status: concreteStatus,
          deadline: concreteDate,
          comment: this.textAreaComment,
        };

        var optionAxios = {
          headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
            Authorization: `Bearer ${this.id_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf8",
          },
        };
        this.setLoadingBlock();
        axios
          .patch(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_GET_TASK +
              "/" +
              this.responseFromLoading.id,
            body,
            optionAxios
          )
          .then((response) => {
            this.errorAxios = false;
            this.completeLoadingBlock();
            this.dialogSave = true;
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      } else {
        this.dialogError = true;
      }
    },

    onChangePerson() {
      this.textName = this.actualResponsiblePerson;
      if (!this.isExistPerson(this.actualResponsiblePerson)) {
        this.personType = "person";
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },
    closeAddPerson() {
      this.selectResponsiblePerson = "";
      this.textPhone = "";
      this.textPosition = "";
      this.selectOvmAddUser = "";
      this.textEmail = "";

      this.dialogNewPerson = false;
    },
    findOvmByName(name) {
      return this.itemOvmObj.filter((p) => p.name === name);
    },
    addPerson() {
      let newPerson = "";
      if (this.selectOvmUser == "" || this.textName == "") {
        this.dialogError = true;
      } else {
        if (this.textEmail == "") this.textEmail = "-";
        if (this.textPosition == "") this.textPosition = "-";
        if (this.textPhone == "") this.textPhone = "-";
        let ovm = this.findOvmByName(this.selectOvmUser);

        let body = {
          ovm: { id: ovm[0].id, name: ovm[0].name, exist: ovm[0].exist },
          name: this.textName,
          email: this.textEmail,
          position: this.textPosition,
          phone: this.textPhone,
        };
        var optionAxios = {
          headers: {
            Authorization: `Bearer ${this.id_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf8",
          },
        };
        this.setLoadingBlock();
        axios
          .post(
            process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON,
            body,
            optionAxios
          )
          .then((response) => {
            newPerson = response.data;
            this.setLoadingBlock();
            this.loadPersons();

            let personTextFieldName =
              newPerson.name +
              ", " +
              newPerson.position +
              ", " +
              newPerson.ovm.name;

            this.selectResponsiblePerson = personTextFieldName;
            this.actualResponsiblePerson = personTextFieldName;

            this.dialogNewPerson = false;
            this.textName = "";
            this.personType = "";
            this.textPosition = "";
            this.textPhone = "";
            this.selectOvmUser = "";
            this.textEmail = "";
            this.errorAxios = false;
            this.completeLoadingBlock();
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      }
    },
  },
};
</script>
